import { useEffect, useState } from 'react'
import { useGlobalModalContext, MODAL_TYPES } from '../components/modals/GlobalModal'
import { Button, Modal } from 'flowbite-react'
import { useContextState } from '../context/Context'
import { useEventApi } from "../store";
import { useParams } from 'react-router-dom';
import SecondHeader from '../components/headers/SecondHeader';
import EventPictures from '../components/events/EventPictures';
import Loader from '../components/shared/Loader';
import EventPricing from '../components/events/eventPricing'
import EventInfo from '../components/events/EventInfo'
import EventPromoter from '../components/events/EventPromoters'
import Tickets from '../components/events/Tickets'
import ListWithIcons from '../components/shared/ListWithIcons'
import AlertComponent from '../components/shared/AlertComponent'
import PaypalPayouts from '../components/paypal/PaypalPayouts'
import { ImCancelCircle } from 'react-icons/im'
import { BsQrCodeScan } from "react-icons/bs";
import ValidateActionWithPassword from '../components/shared/ValidateActionWithPassword'
import EventDetails from '../components/events/eventDetails'
import Footer from '../components/footers/Footer'
import ApplicationBar from '../components/shared/ApplicationBar';
import EventImagesUpload from '../components/events/EventImagesUpload';
import Scanner from '../components/scan/Scanner';
import { useShared } from '../store';



export default function Event(fromPage?:any) {
  const { showModal } = useGlobalModalContext();
  const [openModal, setOpenModal] = useState<string | undefined>();
  const props = { openModal, setOpenModal,  };


  const { eventsCTX } = useContextState();
  const { allEventsState : { currentEventId }, eventPageType, dispatchAllEventsState } = eventsCTX
  const { approveEventTicketRequest, cancelEvent, scanGuest, getEvent } = useEventApi()

  const { isThisAPastTime } = useShared()

  const [loaded, setLoaded] = useState<boolean>(false);

  const [showResultModal, setShowResultModal] = useState<boolean>(false);

  const [currentEvent, setCurrentEvent] = useState<any>();
  

  const [currentView, setCurrentView] = useState<string>('event');
  const [currentSubView, setCurrentSubView] = useState<string>('');
  const [eventHasEnded, setEventHasEnded] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');

  var { eventId } = useParams()

  useEffect(() => {
    // console.log(currentEventId)
    if (eventId){
      getEventInfo();
    }
    else if (currentEventId){
      eventId = currentEventId
      getEventInfo();
    }
  }, [currentEventId, eventId]);


  const getEventInfo = async () =>{
    // console.log('id: ', eventId)
    if (eventId){
        const apiCall = await getEvent({eventId, eventPageType}).then((result:any) => {
            // console.log('lol: ', result)
            if (result && result.res === 'success'){
                // console.log(result.data[0])

                setEventHasEnded(isThisAPastTime(result.data[0].endDate.toString() + ' ' + result.data[0].endTime.toString()))

                dispatchAllEventsState({ 
                    type: 'SET_CURRENT_EVENT_ID', 
                    payload: result.data[0].id
                });

                setCurrentEvent(result.data[0])
                document.title = `Festishare - You are invited to ${result.data[0].name}`
                setCurrentSubView('default')

                // return result.data[0]
            }
        });
    }else{
      setCurrentEvent({})
      // return {}
    }
  }

  
  const handleCashOut = () => {
    setShowResultModal(true); 
    setCurrentSubView('Cash Out');
  }

 
  const handleOpenAddImages = () => {
    setCurrentView('addImages');
  }

  const handleImageAdd = () => {
    setCurrentView('pictures');
  }

  const profileModal = (profileId:string) => {
    props.setOpenModal('initial-focus');
    showModal(MODAL_TYPES.PROFILE_MODAL, { title: "Create instance form", confirmBtn: "Close", profileType:'guest', profileId:profileId });
  };

  const approveRequest = async(ticketId:string) => {
    const apiCall = await approveEventTicketRequest({ticketId: ticketId, eventId:currentEvent.id}).then((result:any) => {
      if (result && result.res === 'success'){
          setMessage('You have successfully approved this guest ...');
          setShowResultModal(true)
          setCurrentSubView('success')
      }else{setMessage('Sorry, there was an error ...'); setShowResultModal(true); setCurrentSubView('error')}
    });
  }

  // const NewCurrentEvent = () => {
  //   return currentEvent
  // }


  // const prices = currentEvent.price;
  // const hosts = currentEvent.host;

  // console.log('tickets: ', currentEvent)

  return (
    <div className="bg-white">

      {eventPageType === "" ? 
        <SecondHeader headerType='event' callBack={setCurrentView}/>:null
      }

      {eventPageType === 'myevent'? 
        <SecondHeader headerType='myevent' callBack={setCurrentView}/>:null
      }

      {eventPageType === 'myticket'? 
        <div className='sticky top-20 z-10'>
          {((typeof currentEvent !== 'undefined') && ((currentEvent.tickets[0]?.status === 'request') || (currentEvent.tickets[0]?.status === 'requestApproved')))?
            <SecondHeader headerType='myrequest' callBack={setCurrentView} />:<SecondHeader headerType='myticket' callBack={setCurrentView} />
          }
        </div>:null
      }

      { currentView === 'loader'? <Loader />: null }

      {currentView === 'event'?
        <EventDetails 
          // eventId={eventId} 
          event={currentEvent} 
          // event={NewCurrentEvent()} 
          showAddress={((eventPageType === 'myevent') || (eventPageType === 'myticket'))? true:false}
          callBack={handleCashOut} 
          ticketCallBack={setCurrentView} 
        />:null
      }

      {currentView === 'guests'? 
        <div>
          <div className="w-full flex justify-center mt-16">
            <Button type='button' gradientMonochrome="success" pill onClick={()=>{setCurrentSubView("Scan Guests"); return setShowResultModal(true)}}>
              <BsQrCodeScan className="h-5 w-5 mr-3" /> 
              Scan Guests
            </Button>
          </div>
          <ListWithIcons
            titleId='user'
            status='active' 
            callBackId='user' 
            callBack={profileModal} 
            itemOptions={[{'name':'View profile', 'callBack': profileModal, 'callBackId': 'user'}]} 
            listItems={currentEvent.guests} 
            subtitleId="status"
            subtitleName ='Status'
          />
        </div>:null
      }

      {currentView === 'requestApproved'? 
        <div className="mt-16">
          <ListWithIcons 
            titleId= 'user'
            status='requestApproved'
            callBackId='user' 
            callBack={profileModal} 
            itemOptions={[{'name':'View profile', 'callBack': profileModal, 'callBackId': 'user'}]} 
            listItems={currentEvent.guests} 
            subtitleId="status"
            subtitleName ='Status'
          />
        </div>:null
      }

      {currentView === 'requests'? 
        <div className="mt-16">
        <ListWithIcons 
          status='request'
          titleId='user' 
          callBackId='user'
          callBack={profileModal} 
          itemOptions={[{'name':'View profile', 'callBack': profileModal, 'callBackId': 'user' }, {'name':'Accept', 'callBack': approveRequest, 'callBackId': 'id'}]} 
          listItems={currentEvent.guests} 
          subtitleId="status"
          subtitleName ='Status'
        />
        </div>:null
      }

      {currentView === 'promoters'? 
        <EventPromoter event={currentEvent} eventHasEnded={eventHasEnded} />:null
      }

      {
        currentView === 'edit'? 
        <div className='mt-8'>
          
          {(currentEvent.status === 'upcoming') && (!eventHasEnded)?
           <div>
              <EventInfo event={currentEvent} callBack={()=>{return setCurrentView}} showEventInfoSubmit={true} />
              <div className="w-full flex justify-center mt-3">
                <Button type='button' gradientMonochrome="failure" pill onClick={()=>{setCurrentSubView("Cancel Event"); return setShowResultModal(true)}}><ImCancelCircle /> Cancel Event</Button>
              </div>
            </div>:
            <div className="w-full flex justify-center mt-20 mb-20">
              <h6>Sorry you can only edit upcoming events.</h6>
            </div>
          }
        </div>
        :null
      }

      {currentView === 'packages'? 
        <EventPricing event={currentEvent} eventHasEnded={eventHasEnded}/>:null
      }

      {currentView === 'pictures'? 
        <EventPictures callBack={handleOpenAddImages} eventHasEnded={eventHasEnded} />:null
      }

      {
        currentView === 'addImages'? <EventImagesUpload callBack={handleImageAdd} eventId={currentEvent.id} />:null
      }

      {currentView === 'qrcode'? 
        <Tickets eventId={currentEvent.id} eventName={currentEvent.name} tickets={[currentEvent.tickets[0]]} />:null
      }

      {currentView === 'tickets'? 
        <Tickets eventId={currentEvent.id} eventName={currentEvent.name} tickets={currentEvent.tickets} />:null
      }

      <Modal show={showResultModal} size="3xl" onClose={() => setShowResultModal(false)}>
        <Modal.Header>{currentSubView}</Modal.Header>
        <Modal.Body>
          {currentSubView === 'Scan Guests'? 
            <Scanner 
              eventId={currentEvent.id}
              onSuccess={() => {setCurrentSubView('default'); setShowResultModal(false)}}
              callBack={scanGuest} 
            />:null
          }

          {currentSubView === 'Cancel Event'? 
            <ValidateActionWithPassword 
              eventId={currentEvent.id}
              onSuccess={() => {setCurrentSubView('default'); setShowResultModal(false)}}
              callBack={cancelEvent} 
            />:null
          }
          {currentSubView === 'Cash Out'? 
            <PaypalPayouts 
              amount={currentEvent.cash_balance} 
              transactionType = 'event'
              modelId={currentEvent.id}
              callBack={() => {setCurrentSubView('default'); setShowResultModal(false)}} 
            />:null
          }

          {(currentSubView ==='success')? 
              <AlertComponent callBack={() => {setCurrentSubView('default'); setShowResultModal(false)}} 
              type='success' 
              subject='Success' 
              message={message} />: null
          } 

          {(currentSubView ==='error')? 
              <AlertComponent callBack={() => {setCurrentSubView('default'); setShowResultModal(false)}} 
              type='error' 
              subject='Failed' 
              message={message} />: null
          } 
        </Modal.Body>
      </Modal>


      <div className="bottom-0"><Footer /></div>
      
      <ApplicationBar />
    </div>
  )
}
